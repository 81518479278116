import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import ResponsiveDrawer from './Drawer';


function App() {
  return (
    <Box>
     <ResponsiveDrawer/>
    </Box>
  );
}

export default App;
